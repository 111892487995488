nav ul{
    list-style-type: none;
}
nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
}
.menu{
    display: flex;
}
.menu a, .login a{
    margin: 0 15px;
    color: #1f4c4d;
    font-weight: 600;
    text-decoration: none;
}
nav a:hover{
    color:#13595a;
    cursor: pointer;
}
.brand{
    font-size: 28px;
    font-weight: 700;
}
.mobile-menu{
    display: none;
}
@media only screen and (max-width:768px) {
    .mobile-menu{
        display: flex;
    }
    .menu,.login{
        display: flex;
        flex-direction: column;
    }
    nav a{
        margin: 10px;
    }
    nav .container{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    nav {
        position: relative;
    }
    .menu{
        position: absolute;
        top:100%;
        width: 100%;
        padding: 20px;
        left:0;
        text-align: center;
        background: #ffffff;
        display: none;
    }
    .menu.show{
        display: flex;
    }
}
