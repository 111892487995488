/* BookingConfirmation.css */

/* Center the container vertically and horizontally */
.container-center {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f8f9fa;
}

/* Card Styles */
.card-custom {
  max-width: 500px;
  width: 100%;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.card-custom .check-icon {
  width: 64px;
  height: 64px;
  color: #28a745;
}

.card-custom h2 {
  font-size: 1.75rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.card-custom p {
  font-size: 1rem;
  color: #6c757d;
}

.card-custom .btn-outline-primary {
  margin-top: 1rem;
}
